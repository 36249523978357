// Here you can add other styles
.sidebar-nav {
  overflow-y: auto;
}

.sidebar-nav::-webkit-scrollbar {
  width: 0;
  display: none;
}

.sidebar-brand {
  display: block;
  padding: 10px 0;
  // background: #fff;
}

.logo-box {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo-title {
  margin-left: 10px;
  margin-bottom: 0;
  color: #fff;
  font-size: 1.8rem;
  letter-spacing: -1px;
}

// inquiry
.inquiry-button {
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 50px;
}

// push
.select-area {
  display: flex;
  justify-content: space-between;
}

.push-button {
  margin-bottom: 30px;
}

// chat box
.card-full {
  height: 100vh;

  .card-header {
    max-height: 75px;
  }

  .card-body {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    .card {
      flex: 1
    }

    .chat-wrapper {
      height: auto;
    }
  }

  .chat-input {
    width: 100%;
  }
}

.chat-wrapper {
  height: 300px;
  gap: 4px;
}

.check-unread {
  font-size: 0.8125rem;
  text-align: right;
}

.chat-item {
  border: 1px solid $info;
  padding: 8px;
  border-radius: 16px;
}

.chat-input {
  width: 350px;
}

.chat-send-btn {
  min-width: 110px;
}

.chat-modal-body {
  width: 800px;
}

.chat-card-body {
  height: 750px;
}
